import React from 'react';

import { useTranslation } from 'react-i18next';

import logo from '../assets/images/logo.png';

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="bg-brown-secondary px-6 pt-[36px] pb-[56px]">
      <a href="/" className="lg:m-auto">
        <img src={logo} alt={logo} className="lg:m-auto" />
      </a>
      <div className="mt-[34px] m-auto md:max-w-[1016px] lg:text-center">
        <h3 className="font-sans font-bold lg:text-xl">{t('footer:heading-1')}</h3>
        <p className="mt-[10px] text-[12px] leading-[16px]  lg:text-base">
          {t('footer:subheading-1')}
        </p>
      </div>

      <div className="mt-[34px] m-auto md:max-w-[1016px] lg:text-center">
        <h3 className="font-sans font-bold lg:text-xl">{t('footer:heading-2')}</h3>
        <p className="mt-[10px] text-[12px] leading-[16px] lg:text-base">
          {t('footer:subheading-2')}&nbsp;
          <a className="underline" href="/contact">
            contact us
          </a>
        </p>
      </div>
      <div className="font-sans text-base w-full flex flex-col items-start justify-center gap-[25px] mt-[34px] lg:mt-[96px] lg:text-xl lg:flex-row lg:items-center lg:justify-center lg:gap-8">
        <a href="/" className="transition hover:text-tertiaryOrange">
          Home
        </a>
        <div className="hidden h-[15px] border-[1px] border-primaryOrange lg:block"></div>
        <a href="/about" className="transition hover:text-tertiaryOrange">
          About
        </a>
        <div className="hidden h-[15px] border-[1px] border-primaryOrange lg:block"></div>
        <a href="/opt-out" className="transition hover:text-tertiaryOrange">
          Opt Out
        </a>
        <div className="hidden h-[15px] border-[1px] border-primaryOrange lg:block"></div>
        <a href="/contact" className="transition hover:text-tertiaryOrange">
          Contact
        </a>
        <div className="hidden h-[15px] border-[1px] border-primaryOrange lg:block"></div>
        <a href="/terms-and-conditions" className="transition hover:text-tertiaryOrange">
          Terms & Conditions
        </a>
        <div className="hidden h-[15px] border-[1px] border-primaryOrange lg:block"></div>
        <a href="/privacy-policy" className="transition hover:text-tertiaryOrange">
          Privacy Policy
        </a>
      </div>
    </footer>
  );
};
