import React, { useContext, useEffect } from 'react';

import { RootContext } from '../context/RootContext';

import { useTranslation } from 'react-i18next';

import { Button } from '../components/index.js';

import { homePageGame } from '../data/gamesList';

import gameImage from '../assets/images/game-1.png';

export const Home = () => {
  const { checkForLoggedIn } = useContext(RootContext);
  const { t } = useTranslation();

  useEffect(() => {
    document.title = 'MagicianSlots | Home';
  }, []);

  return (
    <>
      <section className="bg-[url('./assets/images/bg.webp')] bg-cover bg-no-repeat bg-center lg:bg-cover py-4 px-6 md:bg-cover lg:-mt-[80px] lg:pt-28 lg:pb-96">
        <div className="m-auto flex flex-col items-center justify-center text-center max-w-[608px]">
          <h1 className="order-1 text-[26px] font-bold leading-8 lg:text-[42px] lg:leading-[54px] lg:mt-20 lg:mb-[0px]">
            {t('home:heading-1')}
          </h1>
          <p className="order-3 text-[13px] leading-[18px] mt-[70px] lg:text-xl lg:leading-[28px] lg:order-2 lg:mt-[23px]">
            {t('home:subheading-1')}
          </p>
          <div className="mt-10 order-2 p-[6px] m-auto lg:order-3">
            <Button title="Play Now" buttonClass="tertiaryTransparent" onClick={checkForLoggedIn} />
          </div>
        </div>
      </section>
      <section className="px-6 lg:bg-[url('./assets/images/bg-2.png')] bg-cover bg-no-repeat bg-center">
        <div className="flex flex-col items-center justify-center m-auto mt-8 lg:flex-row lg:gap-[88px] lg:mt-0 lg:pt-8 lg:pb-8">
          <img className="w-full max-w-[300px] lg:max-w-[500px]" src={gameImage} alt={gameImage} />
          <div className="text-center flex flex-col items-center mt-10 max-w-[583px]">
            <h1 className="text-[26px] font-bold leading-8 lg:text-[42px] lg:leading-[54px]">
              {t('home:heading-2')}
            </h1>
            <p className="text-[13px] leading-[18px] mt-4 lg:text-xl lg:leading-[28px]">
              {t('home:subheading-2')}
            </p>
            <div className="mt-10">
              <Button
                title="Play Now"
                buttonClass="tertiaryTransparent"
                onClick={checkForLoggedIn}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="px-6 bg-[url('./assets/images/bg-3.png')] bg-contain bg-top lg:bg-cover bg-no-repeat lg:py-32">
        <div className="mt-8 text-center flex flex-col items-center max-w-[608px] m-auto">
          <h1 className="mb-20 text-[26px] font-bold leading-8 lg:text-[42px] lg:leading-[54px]">
            {t('home:heading-3')}
          </h1>
          <p className="text-[13px] leading-[18px] lg:text-xl lg:leading-[28px]">
            {t('home:subheading-3')}
          </p>
          <div className="mt-4 mb-10 lg:mt-10">
            <Button
              title="About Us"
              buttonClass="tertiaryTransparentLink"
              anchorLink
              anchorHref="/about"
            />
          </div>
        </div>
      </section>
    </>
  );
};
