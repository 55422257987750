import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { GoBack } from '../components';

export const Cookies = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = 'MagicianSlots | Cookie Policy';
  }, []);

  return (
    <section className="mt-10 px-4 mb-10 min-h-[70vh]">
      <h1 className="text-3xl leading-[54px] lg:text-[40px]">Cookie Policy</h1>
      <div className="text-base mt-[16px] lg:text-xl lg:leading-[32px] lg:mt-10">
        {t('cookie:content')}
        <p>{t('cookie:line1')}</p>
        <p>{t('cookie:line2')}</p>
        <p>{t('cookie:line3')}</p>
        <p>{t('cookie:line4')}</p>
        <p>{t('cookie:line5')}</p>
        <p>{t('cookie:line6')}</p>
        <p>{t('cookie:line7')}</p>
        <p>{t('cookie:line8')}</p>
        <p>{t('cookie:line9')}</p>
        <p>{t('cookie:line10')}</p>
        <p>{t('cookie:line11')}</p>
        <p>{t('cookie:line12')}</p>
        <p>{t('cookie:line13')}</p>
        <p>{t('cookie:line14')}</p>
      </div>
    </section>
  );
};
