import React, { useEffect, useState } from 'react';

import { Button } from '../components/index.js';

import contactImage from '../assets/images/contact.webp';

export const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [messageError, setMessageError] = useState('');
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    document.title = 'MagicianSlots | Contact Us';
  }, []);

  const validateInputs = () => {
    let isValid = true;
    if (name.trim().split(' ').length !== 2) {
      setNameError('Please fill name correctly!\n');
      isValid = false;
    } else {
      setNameError('');
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError('Invalid email');
      isValid = false;
    } else {
      setEmailError('');
    }

    if (message.trim() === '') {
      setMessageError('Message cannot be empty');
      isValid = false;
    } else {
      setMessageError('');
    }

    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateInputs()) {
      setSubmitted(true);
      setName('');
      setEmail('');
      setMessage('');

      setTimeout(() => {
        setSubmitted(false);
      }, 5000);
    }
  };

  return (
    <section className="flex items-center justify-around px-4 mb-10">
      {submitted ? (
        <div className="h-[80vh] flex items-center justify-center">
          <div className="flex flex-col text-center">
            <h1 className="text-2xl font-bold lg:text-[42px]">Thank You!</h1>
            <p>We'll get back to you soon.</p>
          </div>
        </div>
      ) : (
        <>
          <form
            onSubmit={handleSubmit}
            className="flex flex-col items-center gap-4 w-full lg:max-w-[513px]"
          >
            <h1 className="text-2xl font-bold lg:text-[42px]">Contact Us</h1>
            <p className="text-[13px] leading-[18px] lg:text-xl lg:mt-[15px]">
              Please fill in the form to contact us!
            </p>
            <input
              placeholder="Enter a full name"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full font-sans px-6 py-2 text-black outline-0 rounded-full lg:py-[18px] lg:rounded-[10px]"
            />
            {nameError && <span className="text-red mt-[-10px] mb-[-10px]">{nameError}</span>}
            <input
              placeholder="Enter a full email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full font-sans px-6 py-2 text-black outline-0 rounded-full lg:py-[18px] lg:rounded-[10px]"
            />
            {emailError && <span className="text-red mt-[-10px] mb-[-10px]">{emailError}</span>}
            <textarea
              placeholder="Your message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="h-[83px] overflow-hidden resize-none w-full font-sans px-6 py-2 text-black outline-0 rounded-[25px] lg:py-[18px] lg:rounded-[10px]"
            />
            {messageError && <span className="text-red mt-[-10px] mb-[-10px]">{messageError}</span>}
            <div className="m-auto mt-4 mb-10 p-[6px]">
              <Button title="Submit" buttonClass="tertiaryTransparent" />
            </div>
          </form>
          <img
            src={contactImage}
            alt={contactImage}
            className="hidden md:block md:max-w-[450px] lg:max-w-[690px]"
          />
        </>
      )}
    </section>
  );
};
