import React from 'react';
import { Outlet } from 'react-router';

import {
  AgePopup,
  CookiePopup,
  Footer,
  Header,
  LoginPopup,
  SignupPopup,
  ThankYouPopup,
} from '../components';

export const Layout = (props) => {
  return (
    <>
      <Header />
      <div className="w-full">
        <div className="w-full max-w-[1527px] m-auto relative">{props.children ?? <Outlet />}</div>
      </div>
      <Footer />
      <CookiePopup />
      <ThankYouPopup />
      <AgePopup />
      <SignupPopup />
      <LoginPopup />
    </>
  );
};
