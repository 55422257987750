import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const PrivacyPolicy = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = 'MagicianSlots | Privacy Policy';
  }, []);

  const renderLines = () => {
    const lines = [];
    const lng = localStorage.getItem('lng');

    if (lng === 'bing') {
      for (let i = 1; i <= 52; i++) {
        const lineKey = `privacy:line${i}`;
        if (t(lineKey)) {
          lines.push(<p key={`line-${i}`}>{t(lineKey)}</p>);
        }
      }
    } else {
      for (let i = 1; i <= 20; i++) {
        const lineKey = `privacy:line${i}`;
        if (t(lineKey)) {
          lines.push(<p key={`line-${i}`}>{t(lineKey)}</p>);
        }
      }
    }

    return lines;
  };

  return (
    <section className="mt-10 px-4 mb-10 min-h-[70vh]">
      <h1 className="text-3xl leading-[54px] lg:text-[40px]">Privacy Policy</h1>
      <div className="text-base mt-[16px] lg:text-xl lg:leading-[32px] lg:mt-10">
        <p>
          Company information: <br />
          Magicianslots, a limited liability company under US law. With the trade name
          “Magicianslots” with registered office at 200 Spectrum Center Drive Suite 300 Irvine CA
          92618 (hereinafter: “Magicianslots”, “us”, “we”, or “our”) we operate the following site:
          <br />
          <a className="text-tertiaryOrange hover:underline" href="/">
            www.magicianslots.com
          </a>
          <br />
          <br />
          At Magicianslots, safeguarding your privacy is paramount to us. This Privacy Policy
          elaborates on how we collect, use, disclose, and protect your information when you engage
          with our website and services. Please note that Magicianslots cannot be held accountable
          for the privacy policies of other websites or sources. Based on this Privacy Policy
          Magicianslots acts as a data controller, committed to safeguarding your privacy. By
          clicking “Agree & Continue,” you acknowledge your consent to this Privacy Policy and
          affirm that you are of legal age. We highly value the trust you place in us and consider
          it our duty to protect your privacy. Here, we outline the information we collect when you
          use our Site, why we collect it, and how we enhance your user experience, providing you
          with a clear understanding of our practices.
        </p>
        <br />
        {renderLines()}
      </div>
    </section>
  );
};
