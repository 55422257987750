import React, { useState, useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { Button } from './Button.jsx';
import { Checkbox } from './Checkbox.jsx';

export const AgePopup = () => {
  const [isAgePopupOpen, setIsAgePopupOpen] = useState(false);
  const [isAgeConfirmed, setIsAgeConfirmed] = useState(localStorage.getItem('age-confirmed'));
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [showError, setShowError] = useState(false);
  const [ageRejected, setAgeRejected] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (!isAgeConfirmed) {
      setIsAgePopupOpen(true);
    }
  }, [isAgeConfirmed]);

  const confirmAge = () => {
    if (isCheckboxChecked) {
      localStorage.setItem('age-confirmed', true);
      setIsAgeConfirmed(true);
      setIsAgePopupOpen(false);
    } else {
      setShowError(true);
    }
  };

  const rejectAgeConfirmation = () => {
    setAgeRejected(true);
  };

  const handleCheckboxChange = () => {
    setIsCheckboxChecked(!isCheckboxChecked);
    setShowError(false);
  };

  if (ageRejected) {
    return (
      <>
        <div className="z-50 fixed h-full w-full inset-0 flex items-center justify-center bg-black bg-opacity-60">
          <div className="mx-4 flex flex-col items-center justify-center h-[369px] w-[631px] rounded-lg bg-gradient-to-b from-popupOrangePrimary to-popupOrangeSecondary shadow-md">
            <h1 className="text-[38px] font-bold text-center leading-[50px]">
              Sorry,
              <br />
              we can’t let you play game <br />
              :(
            </h1>
          </div>
        </div>
      </>
    );
  }

  if (window.location.pathname === '/terms-and-conditions/') {
    return <></>;
  }

  return (
    <>
      {isAgePopupOpen && (
        <div className="z-50 fixed h-full w-full inset-0 flex items-center justify-center bg-black bg-opacity-60">
          <div className="mx-4 p-2 pb-10 md:mx-0 flex flex-col w-[631px] rounded-lg bg-gradient-to-b from-popupOrangePrimary to-popupOrangeSecondary shadow-md">
            <h1 className="mt-[20px] md:mt-[70px] text-[38px] font-bold text-center leading-[50px]">
              Are You Over 21 Years Old?
            </h1>
            <div className="flex flex-col items-center mt-[35px] mx-auto">
              <div className="flex items-center">
                <Checkbox
                  id="myCheckbox"
                  checked={isCheckboxChecked}
                  onChange={handleCheckboxChange}
                />
                <label className="ml-2 text-xl">
                  I agree to{' '}
                  <a className="underline" href="/terms">
                    Terms & Conditions
                  </a>
                </label>
              </div>
              {!isCheckboxChecked && showError && (
                <span className="text-red text-xl mt-2 ml-1">This field is required!</span>
              )}
            </div>
            <div className="flex items-center justify-center gap-[25px] md:gap-[55px] mt-[60px] text-[32px]">
              <div className="max-w-[180px] w-full">
                <Button
                  buttonClass="primary"
                  title="Yes"
                  onClick={confirmAge}
                  disabled={!isCheckboxChecked}
                />
              </div>
              <div className="max-w-[180px] w-full">
                <Button buttonClass="secondary" title="No" onClick={rejectAgeConfirmation} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
