import React, { useEffect } from 'react';

import notFoundImage from '../assets/images/404.png';

export const NotFoundPage = () => {
  useEffect(() => {
    document.title = 'MagicianSlots | Not found page 404';
  }, []);

  return (
    <section className="h-full flex flex-col items-center justify-center">
      <img src={notFoundImage} alt="notFoundImage" />
    </section>
  );
};
