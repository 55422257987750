import React, { useEffect, useState } from 'react';

import { Button } from './Button.jsx';

export const CookiePopup = () => {
  const [showCookiePopup, setShowCookiePopup] = useState(false);

  useEffect(() => {
    const hasAcceptedCookies = localStorage.getItem('acceptedCookies');

    if (!hasAcceptedCookies) {
      setShowCookiePopup(true);
    }
  }, []);

  const handleClick = () => {
    localStorage.setItem('acceptedCookies', 'true');
    setShowCookiePopup(false);
  };

  return (
    showCookiePopup && (
      <div className="z-50 fixed w-full text-center max-w-[375px] bottom-0 px-8 pb-10 rounded-lg bg-gradient-to-b from-popupOrangePrimary to-popupOrangeSecondary shadow-md">
        <h1 className="mt-[20px] text-[38px] font-bold text-center leading-[50px]">
          We Use Cookies
        </h1>
        <p className="mt-[20px]">
          We use our own and third-party cookies to personalize content and to analyze web traffic.
          <br />
          <a href="/cookies" className="underline cursor-pointer">
            Read more about cookies
          </a>
        </p>
        <div
          className="flex items-center justify-center gap-[25px]
       mt-8 md:gap-[55px] text-xl font-bold"
        >
          <div className="max-w-[180px] w-full">
            <Button buttonClass="primary" title="Accept" onClick={handleClick} />
          </div>
          <div className="max-w-[180px] w-full">
            <Button
              buttonClass="secondary"
              title="Reject"
              onClick={() => setShowCookiePopup(false)}
            />
          </div>
        </div>
      </div>
    )
  );
};
