import theShadowOrder from '../assets/images/games/the-shadow-order.png';
import bootyBay from '../assets/images/games/booty-bay.png';
import fatBanker from '../assets/images/games/fat-banker.png';
import eyeOfAtum from '../assets/images/games/eye-of-atum.png';
import fruitBonanza from '../assets/images/games/fruit-bonanza.png';
import goldKing from '../assets/images/games/gold-king.png';
import jadeButterfly from '../assets/images/games/jade-butterfly.png';
import theGreatChickenEscape from '../assets/images/games/the-great-chicken-escape.png';
import elvesKingdom from '../assets/images/games/elves-kingdom.png';
import madCars from '../assets/images/games/mad-cars.png';
import pachinGirl from '../assets/images/games/pachin-girl.png';
import casinoHoldem from '../assets/images/games/casino-holdem.png';
import wheelTime from '../assets/images/games/wheel-time.png';
import giftShop from '../assets/images/games/giftshop.png';
import jadeMag from '../assets/images/games/jade-mag.png';

export const gamesList = [
  {
    url: 'the-shadow-order',
    title: 'The Shadow Order',
    img: theShadowOrder,
    link: 'https://d1k6j4zyghhevb.cloudfront.net/casino/games/shadoworder/index.html?gameid=shadoworder&jurisdiction=SE&channel=web&moneymode=fun&partnerid=4&lang=en_EN',
  },
  {
    url: 'gift-shop',
    title: 'Gift Shop',
    img: giftShop,
    link: 'https://asccw.playngonetwork.com/casino/IframedView?pid=2&gid=giftshop&gameId=107&lang=en_GB&practice=1&channel=desktop&div=flashobject&width=100%&height=100%&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country=',
  },
  {
    url: 'casino-holdem',
    title: 'Casino Holdem',
    img: casinoHoldem,
    link: 'https://asccw.playngonetwork.com/casino/IframedView?pid=2&gid=casinoholdem&gameId=57&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%&height=100%&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country=',
  },
  {
    url: 'eye-of-atum',
    title: 'Eye of Atum',
    img: eyeOfAtum,
    link: 'https://asccw.playngonetwork.com/Casino/IframedView?pid=2&gid=eyeofatum&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%25&height=100%25&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country=&pauseplay=&playlimit=&selftest=&sessiontime=&coreweburl=https://asccw.playngonetwork.com/&showpoweredby=True',
  },
  {
    url: 'fruit-bonanza',
    title: 'Fruit Bonanza',
    img: fruitBonanza,
    link: 'https://asccw.playngonetwork.com/casino/IframedView?pid=2&gid=fruitbonanza&gameId=43&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%&height=100%&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country=&pauseplay=&playlimit=&selftest=&sessiontime=',
  },
  {
    url: 'gold-king',
    title: 'Gold King',
    img: goldKing,
    link: 'https://asccw.playngonetwork.com/casino/IframedView?pid=2&gid=goldking&gameId=341&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%&height=100%&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country=',
  },
  {
    url: 'jade-butterfly',
    title: 'Jade Butterfly',
    img: jadeButterfly,
    link: 'https://demogamesfree.pragmaticplay.net/gs2c/openGame.do?lang=en&cur=USD&gameSymbol=vs1024butterfly&websiteUrl=https%3A%2F%2Fdemogamesfree.pragmaticplay.net&jurisdiction=99&lobbyURL=https%3A%2F%2Fwww.pragmaticplay.com',
  },
  {
    url: 'the-great-chicken-escape',
    title: 'The Great Chicken Escape',
    img: theGreatChickenEscape,
    link: 'https://demogamesfree.pragmaticplay.net/gs2c/openGame.do?lang=en&cur=USD&gameSymbol=vs20chicken&websiteUrl=https%3A%2F%2Fdemogamesfree.pragmaticplay.net&jurisdiction=99&lobbyURL=https%3A%2F%2Fwww.pragmaticplay.com',
  },
  {
    url: 'elves-kingdom',
    title: 'Elves Kingdom',
    img: elvesKingdom,
    link: 'https://cors.mannagaming.com/game/ELVES_KINGDOM/?lan=en-US',
  },
  {
    url: 'jade-magician',
    title: 'Jade Magician',
    img: jadeMag,
    link: 'https://asccw.playngonetwork.com/casino/IframedView?pid=2&gid=jademagician&gameId=326&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%&height=100%&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country=&pauseplay=&playlimit=&selftest=&sessiontime=',
  },
  {
    url: '24k-dragon',
    title: '24k Dragon',
    link: 'https://asccw.playngonetwork.com/Casino/IframedView?pid=2&gid=twentyfourkdragon&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%25&height=100%25&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country=&pauseplay=&playlimit=&selftest=&sessiontime=&coreweburl=https://asccw.playngonetwork.com/&showpoweredby=True',
  },
];
