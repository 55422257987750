import React from 'react';

export const GoBack = () => {
  return (
    <div
      className="ml-4 lg:ml-0 flex items-center gap-[5px] cursor-pointer"
      onClick={() => window.history.back()}
    >
      <svg
        width="20"
        height="15"
        viewBox="0 0 20 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.292955 6.79289C-0.0975685 7.18342 -0.0975685 7.81658 0.292955 8.20711L6.65692 14.5711C7.04744 14.9616 7.68061 14.9616 8.07113 14.5711C8.46165 14.1805 8.46165 13.5474 8.07113 13.1569L2.41428 7.5L8.07113 1.84315C8.46165 1.45262 8.46165 0.819457 8.07113 0.428932C7.68061 0.0384078 7.04744 0.0384078 6.65692 0.428932L0.292955 6.79289ZM19.0276 6.5H1.00006V8.5H19.0276V6.5Z"
          fill="#A770ED"
        />
      </svg>
      <p className="text-[24px] leading-[31px] text-purple2">Back</p>
    </div>
  );
};
