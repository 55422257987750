import React, { useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { Button } from '../components/index.js';
import { gamesList } from '../data/gamesList.js';
import ArrowLeftIcon from '../assets/images/arrow-left.svg';
import ArrowRightIcon from '../assets/images/arrow-right.svg';
import 'swiper/css';

export const Top10 = () => {
  useEffect(() => {
    document.title = 'MagicianSlots | Top-10 Games';
  }, []);

  const limitedGamesList = gamesList.slice(0, 10); // Limiting to 20 games

  return (
    <section className="h-full w-full py-10 px-4">
      <Swiper
        modules={[Navigation]}
        slidesPerView={1.4}
        loop={true}
        navigation={{
          prevEl: '.custom-prev',
          nextEl: '.custom-next',
        }}
        className="lg:hidden"
        breakpoints={{
          640: {
            slidesPerView: 2.3,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 5,
            spaceBetween: 50,
          },
        }}
      >
        {limitedGamesList.map((game) => (
          <SwiperSlide key={game.url}>
            <div className="w-full h-full m-auto max-w-[222px] gap-[23px] flex flex-col flex-wrap items-center text-center">
              <img
                src={game.img}
                alt={game.img}
                className="w-full h-[222px] max-w-[222px] rounded-full"
              />
              <Button
                buttonClass="playNow"
                title="Play Now"
                anchorLink
                anchorHref={`/game/${game.url}`}
              />
            </div>
          </SwiperSlide>
        ))}
        <div className="flex w-full items-center justify-center m-auto gap-[40px] mt-[30px]">
          <div className="swiper-button-prev custom-prev">
            <img src={ArrowLeftIcon} alt="arrow-left" />
          </div>
          <div className="swiper-button-next custom-next">
            <img src={ArrowRightIcon} alt="arrow-right" />
          </div>
        </div>
      </Swiper>
      <div className="hidden lg:grid grid-cols-5 gap-10">
        {limitedGamesList.map((game) => (
          <div
            key={game.url}
            className="w-full h-full gap-[23px] max-w-[222px] flex flex-col items-center text-center"
          >
            <img
              src={game.img}
              alt={game.img}
              className="w-full h-[222px] max-w-[222px] rounded-full"
            />
            <Button
              buttonClass="playNow"
              title="Play Now"
              anchorLink
              anchorHref={`/game/${game.url}`}
            />
          </div>
        ))}
      </div>
    </section>
  );
};
