import React, { useContext, useEffect } from 'react';

import { RootContext } from '../context/RootContext';

import { useTranslation } from 'react-i18next';

import { Button } from '../components/index.js';

import aboutImage from '../assets/images/about.png';

export const About = () => {
  const { checkForLoggedIn } = useContext(RootContext);
  const { t } = useTranslation();

  useEffect(() => {
    document.title = 'MagicianSlots | About';
  }, []);

  return (
    <section>
      <div className="flex flex-col items-center justify-center text-center max-w-[331px] mx-auto md:max-w-[600px] lg:gap-6 lg:flex-row lg:items-center lg:justify-center lg:max-w-full lg:px-6 lg:pb-16 lg:mt-16">
        <img
          src={aboutImage}
          alt={aboutImage}
          className="w-full max-w-[315px] md:max-w-[450px] xl:max-w-[719px]"
        />
        <div className="mt-[-40px] pb-[20px] lg:mt-0 lg:pb-0">
          <h1 className="text-[26px] font-bold leading-[32px] xl:text-[42px] xl:leading-[54px]">
            {t('about:heading')}
          </h1>
          <p className="text-[13px] leading-[18px] mt-1 xl:text-xl">{t('about:subheading')}</p>
          <div className="max-w-[200px] lg:max-w-[307px] m-auto mt-10 mb-10 p-[6px]">
            <Button title="Play Now" buttonClass="tertiaryTransparent" onClick={checkForLoggedIn} />
          </div>
        </div>
      </div>
    </section>
  );
};
