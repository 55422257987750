import React, { useContext, useEffect } from 'react';

import { RootContext } from '../context/RootContext';

import { useTranslation } from 'react-i18next';

import { Top10 } from './Top10';

import { Button } from '../components';

import landingBg from '../assets/images/lp-bg-1.png';
import landingImage1 from '../assets/images/lp-1.png';
import landingImage2 from '../assets/images/lp-2.png';
import landingImage3 from '../assets/images/lp-3.png';

export const LandingPage = () => {
  const { checkForLoggedIn } = useContext(RootContext);
  const { t } = useTranslation();

  useEffect(() => {
    document.title = 'MagicianSlots';

    document.body.style.backgroundColor = '#1f0e09';
    document.body.style.backgroundImage = `url('${landingBg}')`;
    document.body.style.backgroundPosition = 'center 35%';
    document.body.style.backgroundSize = 'contain';
    document.body.style.backgroundRepeat = 'no-repeat';

    return () => {
      document.body.style.backgroundColor = '';
      document.body.style.backgroundImage = '';
      document.body.style.backgroundPosition = '';
      document.body.style.backgroundSize = '';
      document.body.style.backgroundRepeat = '';
    };
  });

  return (
    <section className="w-full mt-[20px] lg:mt-[100px]">
      <h1 className="text-[28px] lg:text-[42px] font-bold leading-[54px] text-center">
        {t('landing:heading1')}
      </h1>
      <div className="flex flex-col px-4 lg:px-0 gap-4 justify-center items-center lg:justify-between lg:items-start mt-[24px] lg:flex-row">
        <div className="w-full max-w-[410px] flex flex-col text-center items-center justify-center">
          <img
            className="w-full max-w-[300px] lg:max-w-full"
            src={landingImage1}
            alt={landingImage1}
          />
          <h1 className="text-[24px] lg:text-[36px] leading-[46px]">
            {t('landing:lp-item-heading1')}
          </h1>
          <div className="hidden lg:block mx-auto w-3/4  border-2 border-white mt-2 lg:mt-4"></div>
          <div className="hidden lg:block mx-auto w-3/4  border-2 border-white mt-[5px]"></div>
          <p className="text-[13px] lg:text-base mt-[0px] lg:mt-[22px]">
            {t('landing:lp-item-subheading1')}
          </p>
        </div>
        <div className="w-full max-w-[410px] flex flex-col text-center items-center justify-center">
          <img
            className="w-full max-w-[300px] lg:max-w-full"
            src={landingImage2}
            alt={landingImage2}
          />
          <h1 className="text-[24px] lg:text-[36px] leading-[46px]">
            {t('landing:lp-item-heading2')}
          </h1>
          <div className="hidden lg:block mx-auto w-3/4  border-2 border-white mt-2 lg:mt-4"></div>
          <div className="hidden lg:block mx-auto w-3/4  border-2 border-white mt-[5px]"></div>
          <p className="text-[13px] lg:text-base mt-[0px] lg:mt-[22px]">
            {t('landing:lp-item-subheading2')}
          </p>
        </div>
        <div className="w-full max-w-[410px] flex flex-col text-center items-center justify-center">
          <img
            className="w-full max-w-[300px] lg:max-w-full"
            src={landingImage3}
            alt={landingImage3}
          />
          <h1 className="text-[24px] lg:text-[36px] leading-[46px]">
            {t('landing:lp-item-heading3')}
          </h1>
          <div className="hidden lg:block mx-auto w-3/4  border-2 border-white mt-2 lg:mt-4"></div>
          <div className="hidden lg:block mx-auto w-3/4  border-2 border-white mt-[5px]"></div>
          <p className="text-[13px] lg:text-base mt-[0px] lg:mt-[22px]">
            {t('landing:lp-item-subheading3')}
          </p>
        </div>
      </div>
      <div className="w-full max-w-[307px] mt-10 order-2 p-[6px] m-auto lg:order-3">
        <Button title="Play Now" buttonClass="tertiaryTransparent" onClick={checkForLoggedIn} />
      </div>
      <h1 className="text-[32px] lg:text-[42px] font-bold leading-[54px] text-center mt-10 lg:mt-[180px]">
        {t('landing:heading2')}
      </h1>
      <Top10 />
      <section className="flex flex-col items-center justify-center bg-[url('./assets/images/lp-bg-2.png')] bg-contain lg:bg-cover bg-no-repeat bg-center py-10 pb-60">
        <div className="w-full max-w-[750px] text-center">
          <h1 className="text-[42px] font-bold leading-[54px]">{t('landing:heading3')}</h1>
          <p className="mt-[32px] text-[20px] leading-[28px]">{t('landing:subheading')}</p>
          <div className="w-full max-w-[307px] mt-10 order-2 p-[6px] m-auto lg:order-3">
            <Button title="Play Now" buttonClass="tertiaryTransparent" onClick={checkForLoggedIn} />
          </div>
        </div>
      </section>
    </section>
  );
};
