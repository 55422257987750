import React from 'react';
import { Outlet } from 'react-router';
import { CookiePopup } from './CookiePopup';

export const LayuotWithoutHeaderAndFooter = (props) => {
  return (
    <div className="w-full">
      <div className="w-full max-w-[1527px] m-auto relative">
        <div>{props.children ?? <Outlet />}</div>
      </div>
    </div>
  );
};
