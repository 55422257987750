import React from 'react';

import { Route, Routes } from 'react-router';

import { Layout, LayuotWithoutHeaderAndFooter } from './components/index.js';

import {
  About,
  Contact,
  CookiePolicy,
  Disclaimer,
  Game,
  Home,
  NotFoundPage,
  OptOut,
  TermsAndConditions,
  UsersPage,
  Top10,
  LandingPage,
  PrivacyPolicy,
  Cookies,
  Terms,
} from './pages/index.js';

import './index.css';

export const App = () => {
  return (
    <>
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/opt-out" element={<OptOut />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/top-10" element={<Top10 />} />
          <Route path="/game/:url" element={<Game />} />
          <Route path="/lp" element={<LandingPage />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/cookie-policy" element={<CookiePolicy />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/cookies" element={<Cookies />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
        <Route element={<LayuotWithoutHeaderAndFooter />}>
          <Route path="/userspage" element={<UsersPage />} />
          <Route path="/terms" element={<Terms />} />
        </Route>
      </Routes>
    </>
  );
};
