import React, { useContext, useEffect } from 'react';

import { RootContext } from '../context/RootContext';

import { useParams } from 'react-router';

import { GoBack } from '../components';

import { gamesList } from '../data/gamesList.js';

export const Game = ({ match }) => {
  const { url } = useParams();

  const { loggedIn, registrationCookie, openSignUp } = useContext(RootContext);

  useEffect(() => {
    document.title = 'MagicianSlots | Play';

    if (!loggedIn) {
      setTimeout(() => {
        openSignUp();
      }, 30000);
    }
  }, []);

  const selectedGame = gamesList.find((game) => game.url === url);

  if (!loggedIn && registrationCookie === 'force') {
    window.location.href = '/';
  }

  if (!selectedGame) {
    return <div>Game not found</div>;
  }

  return (
    <section className="min-h-[80vh]">
      <div className="w-full h-[680px] my-10">
        <div className="max-w-[1122px] h-full m-auto">
          <GoBack />
          <iframe className="w-full h-full mt-8" title="game" src={selectedGame.link}></iframe>
        </div>
      </div>
    </section>
  );
};
