import React, { useContext } from 'react';
import { Button } from './Button';
import { RootContext } from '../context/RootContext';

export const ThankYouPopup = () => {
  const { isThankYouPopupOpen } = useContext(RootContext);

  return (
    isThankYouPopupOpen && (
      <div className="fixed top-0 w-full z-40">
        <div className="flex items-center justify-center relative w-full h-[100vh]">
          <div className="z-40 fixed h-full w-full inset-0 flex items-center justify-center bg-black bg-opacity-60"></div>
          <div className="w-full max-w-[679px] border-2 border-primaryOrange absolute z-50 mx-4  md:px-[83px] py-[45px] pb-10 md:mx-0 flex flex-col rounded-lg bg-gradient-to-b from-popupOrangePrimary to-popupOrangeSecondary shadow-md">
            <h1 className="text-[40px] font-bold text-center leading-[50px]">
              Thank You For Subscribing!
            </h1>
            <p className="text-[36px] leading-[46px] text-center mt-2">
              Follow the SMS & Email to Claim Your Welcome Bonus!
            </p>
          </div>
        </div>
      </div>
    )
  );
};
