import React, { useContext, useState } from 'react';

import { RootContext } from '../context/RootContext.jsx';

import { useLocation } from 'react-router-dom';

// import SendThriveRequest from './SendThriveRequest';
import { Button } from './Button.jsx';

import logo from '../assets/images/logo.png';

export const Header = () => {
  const location = useLocation();
  const { loggedIn, openSignUp, openLogin, userLogout } = useContext(RootContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen((prevState) => !prevState);
  };

  const isNavbarHidden = location.pathname === '/lp/';

  return (
    <>
      {!isMenuOpen && (
        <div className="z-[40] sticky top-0 flex items-center justify-center h-[40px] bg-brownBg lg:h-[50px]">
          <span className="font-sans text-sm font-normal">
            Play for free: it’s social gaming platform!
          </span>
        </div>
      )}
      <header className="bg-transparent w-full max-w-[1527px] m-auto relative py-2 z-20 lg:px-2">
        {/*<SendThriveRequest />*/}
        <div className="flex items-center justify-between">
          <a href={isNavbarHidden ? '#' : '/'} className="mb-[8px]">
            <img src={logo} alt={logo} />
          </a>
          <div
            className={
              isMenuOpen
                ? 'flex flex-col align-middle justify-center fixed top-0 bg-darkBg w-full h-full overflow-scroll'
                : 'hidden -top-full lg:flex justify-between w-full'
            }
          >
            <div
              className={
                isNavbarHidden
                  ? 'hidden'
                  : 'text-xl w-full flex flex-col items-center justify-center gap-[34px] lg:gap-[24px] xl:gap-[38px] lg:text-base lg:flex-row lg:justify-end lg:flex-grow lg:flex-shrink-0 lg:w-1/2'
              }
            >
              <a href="/" className="transition hover:text-tertiaryOrange">
                Home
              </a>
              <div className="hidden h-[15px] border-[1px] border-primaryOrange lg:block"></div>
              <a href="/about" className="transition hover:text-tertiaryOrange">
                About
              </a>
              <div className="hidden h-[15px] border-[1px] border-primaryOrange lg:block"></div>
              <a href="/opt-out" className="transition hover:text-tertiaryOrange">
                Opt Out
              </a>
              <div className="hidden h-[15px] border-[1px] border-primaryOrange lg:block"></div>
              <a href="/disclaimer" className="transition hover:text-tertiaryOrange">
                Disclaimer
              </a>
              <div className="hidden h-[15px] border-[1px] border-primaryOrange lg:block"></div>
              <a href="/contact" className="transition hover:text-tertiaryOrange">
                Contact
              </a>
            </div>
            {loggedIn === true ? (
              <div className="w-full flex flex-col items-center justify-center gap-[34px] mt-[33px] lg:flex-row lg:justify-end lg:mt-0 lg:flex-grow lg:flex-shrink-0 lg:w-1/4">
                <div className="w-full max-w-[140px] text-xl items-center justify-center lg:justify-end lg:text-base">
                  <Button onClick={userLogout} title="Log Out" buttonClass="secondaryTransparent" />
                </div>
              </div>
            ) : (
              <div className="w-full flex flex-col items-center justify-center gap-[34px] mt-[33px] lg:flex-row lg:justify-end lg:mt-0 lg:flex-grow lg:flex-shrink-0 lg:w-1/4">
                <div className="flex flex-col gap-4 w-full items-center justify-center lg:justify-end md:flex-row">
                  <div className="w-full max-w-[140px] text-xl lg:text-base ">
                    <Button onClick={openSignUp} title="Sign Up" buttonClass="primaryTransparent" />
                  </div>
                  <div className="w-full max-w-[140px] text-xl lg:text-base">
                    <Button onClick={openLogin} title="Log In" buttonClass="secondaryTransparent" />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div
            className="cursor-pointer absolute right-[24px] top-[21px] z-40 lg:hidden"
            onClick={toggleMenu}
          >
            {isMenuOpen ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="42"
                height="43"
                viewBox="0 0 42 43"
                fill="none"
              >
                <rect
                  x="30.625"
                  y="33.3606"
                  width="29.6985"
                  height="2.47487"
                  rx="1.23744"
                  transform="rotate(-135 30.625 33.3606)"
                  fill="#795245"
                />
                <rect
                  x="9.625"
                  y="31.6104"
                  width="29.6985"
                  height="2.47487"
                  rx="1.23744"
                  transform="rotate(-45 9.625 31.6104)"
                  fill="#795245"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="42"
                height="26"
                viewBox="0 0 42 26"
                fill="none"
              >
                <rect width="41.6667" height="2.88889" rx="1.44444" fill="white" />
                <rect y="11.5555" width="41.6667" height="2.88889" rx="1.44444" fill="white" />
                <rect y="23.1111" width="41.6667" height="2.88889" rx="1.44444" fill="white" />
              </svg>
            )}
          </div>
        </div>
      </header>
    </>
  );
};
